.App {
  text-align: center;
  margin-top: 20vh;
}

.App-header h1 {
  font-size: 3rem;
  color: #333;
}

.App-header p {
  font-size: 1.5rem;
  color: #777;
}
